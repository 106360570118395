.right_box[data-v-6673eb0b] {
  width: 25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
.width_mini[data-v-6673eb0b] {
  width: 4%;
}
.width_small[data-v-6673eb0b] {
  width: 20%;
}
.width_big[data-v-6673eb0b] {
  width: 32%;
}
.width_date[data-v-6673eb0b] {
  width: 10%;
}
.save_box[data-v-6673eb0b] {
  width: 98%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center[data-v-6673eb0b] {
  text-align: center;
  color: #333 !important;
}
.weight[data-v-6673eb0b] {
  font-size: 13px !important;
}
.left[data-v-6673eb0b] {
  text-align: left;
}
.right[data-v-6673eb0b] {
  text-align: right;
}
.left_k[data-v-6673eb0b] {
  text-indent: 41px;
}
.content[data-v-6673eb0b] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content .add[data-v-6673eb0b] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 9px;
  font-size: 20px;
  color: #f15a24;
  cursor: pointer;
}
.content .add i[data-v-6673eb0b] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: var(--themeColor, #17a2b8);
}
.content span[data-v-6673eb0b] {
  line-height: 26px;
  font-size: 13px;
}
.content tr[data-v-6673eb0b] {
  padding: 0;
  margin: 0;
}
.content td[data-v-6673eb0b] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 26px;
  padding: 0 6px;
  color: #666;
  font-size: 13px;
}
.content input[data-v-6673eb0b] {
  width: 100%;
  line-height: 26px;
  padding: 0 6px;
  font-size: 13px;
}
.close_item[data-v-6673eb0b] {
  position: relative;
}
.close_item .close[data-v-6673eb0b] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 0;
  font-size: 20px;
  color: #ff1818;
  cursor: pointer;
}
.close_item .close i[data-v-6673eb0b] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: var(--themeColor, #17a2b8);
}
.big_box4[data-v-6673eb0b] {
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}